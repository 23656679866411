import { useEffect } from "react";
import {writeAnim} from './Item.js'

export default function LoadScreen({ message }) {
  useEffect(() => {
    writeAnim(message,  document.querySelector(".load-wrapper p"), 50)
  }, []);
  const pStyle = {
    maxWidth: "350px",
    textAlign: "center",
  };
  return (
    <div className="load-wrapper off df w100">
      <p className="blink-underscore" style={pStyle}></p>
    </div>
  );
}
