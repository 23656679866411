import {toggleChat} from './Item.js'
function Footer({site}) {
  return (
    <footer className='df w100'>
     {/* <a href="https://github.com/amantes30" style={{ height: " 30px" }}>
             <img
               style={{ height: "100%", width: "30px" }}
               src=" https://amantes30.github.io/icons/SVG/github.svg"
             />
           </a>
           <a href="https://www.linkedin.com/in/amantes30" style={{ height: " 30px" }}>
             <img
               style={{ height: "100%", width: "30px" }}
               src="/linkedin.svg"
             />
           </a>*/}
      <button className="chatbox-toggle p-btn" onClick={toggleChat}>
        {site === "en" ? (
          <p style={{ margin: 0 }}>Chat with me</p>
        ) : (
          <p style={{ margin: 0 }}>与我聊天</p>
        )}
      </button>
    </footer>
  );
}
export default Footer;
